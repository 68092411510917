import { Header } from "components";

export default function NotFound() {
  const renderNotFound = () => (
    <>
      <Header />

      <div
        className="content"
        style={{
          marginTop: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>Page Not Found</h3>
      </div>
    </>
  );

  return renderNotFound();
}
