export const exportCsv = (contractName, rows) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  const columnTitle = Object.keys(rows[0]).join(",");
  csvContent += columnTitle + "\r\n";

  rows.forEach(function (rowArray) {
    const row = Object.values(rowArray).join(",");
    csvContent += row + "\r\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${contractName}_storm_document.csv`);
  document.body.appendChild(link); // NOTE - Required for FF

  link.click();
};
