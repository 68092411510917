import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSignIn, useSignContract, useContractConfig } from "hooks";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { USDollar, exportCsv } from "helpers";
import { DEMO_UID } from "../constants";

import {
  Header,
  Collapsible,
  StormTable,
  PaymentSummary,
  ContractDocument,
  Stakeholders,
  SignCallToAction,
} from "components";

export default function StormContract({ authenticated, isDemo = false }) {
  const { loggedAs } = useSignIn(isDemo);
  const { loading, contracts, requestDownload } = useContractConfig(isDemo);
  const { signContract, newTimeStamps, loadingSignature } = useSignContract();
  const [isSignedForDemo, setSignedForDemo] = useState(false);
  const isBroker = ["broker", "reinsurer"].includes(loggedAs);

  useEffect(() => {
    if (isDemo && newTimeStamps.cedentSignedTimestamp !== "") {
      setSignedForDemo(true);
    }
  }, [newTimeStamps, isDemo]);

  if (authenticated === false) return <Redirect push to="/" />;

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  const renderContent = () => (
    <div className="content">
      <h2>Dashboard View</h2>
      {contracts.length === 0 && (
        <div>
          <p>No contracts found.</p>
        </div>
      )}

      {contracts?.map((item, index) => {
        const active = item.active || (newTimeStamps.cedentSigned && newTimeStamps.reinsurerSigned);
        const showSignToUser =
          loggedAs === "cedent" || (loggedAs !== "reinsurer" && process.env.REACT_APP_DEBUG === "true")
            ? isSignedForDemo || newTimeStamps.cedentSigned || item.timestamps.cedentSigned
            : isSignedForDemo || newTimeStamps.reinsurerSigned || item.timestamps.reinsurerSigned;

        return (
          <Collapsible
            key={index}
            active={active || isSignedForDemo}
            expired={item.expired}
            open={item.open}
            panelItems={{
              confirmationNumber: item.confirmationIDNumber,
              contractName: item.contractName,
              policyPeriod: {
                start: item.start,
                end: item.end,
              },
              clientCompany: item.cedentCompany,
              githubUri: item.githubLink,
            }}
          >
            <div className="collapsible-body">
              <div className={!item.subPeriods.length ? "cards-first-row " : "cards-first-row-pending"}>
                <PaymentSummary
                  hasSubperiods={item.subPeriods.length}
                  content={{
                    reinsurerShare: item.reinsurerShare,
                    showReinsurerShare: item.reinsurerShare && item.reinsurerShare < 1,
                    subjectLoss: USDollar.format(item.subjectLoss || 0),
                    totalLimit: USDollar.format(item.totalLimit || 0),
                    progress: item.subjectLoss ? ((item.totalLimit - item.subjectLoss) * 100) / item.totalLimit : 0,
                    perEventLimit: USDollar.format(item.perStormLimit || 0),
                    perEventAttachment: USDollar.format(item.perStormAttachment || 0),
                    subPeriods: item.subPeriods,
                  }}
                />

                <ContractDocument
                  hasSubperiods={item.subPeriods.length}
                  downloadFile={requestDownload}
                  content={[
                    {
                      name: "Location Exposure and Payout Factors Table",
                      type: item.locationExposure?.type,
                      fileUri: item.locationExposure?.fileUri,
                      uid: item.locationExposure?.uid,
                    },
                    {
                      name: "Reinsurance Agreement",
                      type: item.reinsuranceContract?.type,
                      fileUri: item.reinsuranceContract?.fileUri,
                      uid: item.reinsuranceContract?.uid,
                    },
                  ]}
                />

                <Stakeholders
                  active={active || isSignedForDemo}
                  expired={item.expired}
                  content={{
                    broker: { ID: item.participants.brokerAddress, signedDate: null, showBroker: item.showBroker },
                    cedent: {
                      ID: item.participants.cedentAddress,
                      signedDate: newTimeStamps.cedentSignedTimestamp || item.timestamps.cedentSignedTimestamp,
                      isSigned: isSignedForDemo || newTimeStamps.cedentSigned || item.timestamps.cedentSigned,
                    },
                    reinsurer: {
                      ID: item.participants.reinsurerAddress,
                      signedDate: newTimeStamps.reinsurerSignedTimestamp || item.timestamps.reinsurerSignedTimestamp,
                      isSigned: isSignedForDemo || newTimeStamps.reinsurerSigned || item.timestamps.reinsurerSigned,
                      showReinsurer: item.showReinsurer,
                    },
                  }}
                />
              </div>

              {item.active || item.expired || showSignToUser
                ? item.storms && (
                    <StormTable
                      // NOTE -- Name needs to be the same as the item.storms properties
                      columns={[
                        {
                          name: "name",
                          title: "Storm Name",
                          tooltip: false,
                          downloadable: false,
                          hide: false,
                        },
                        { name: "date", title: "Date", tooltip: false, downloadable: false, hide: false },
                        {
                          name: "grossLoss",
                          title: "Parametric Modeled Loss",
                          tooltip: false,
                          downloadable: false,
                          hide: false,
                        },
                        {
                          name: "subjectLoss",
                          title: "Loss Amount",
                          tooltip: false,
                          downloadable: false,
                          hide: false,
                        },
                        {
                          name: "lossDetailFile",
                          title: "Loss Detail Report",
                          tooltip: false,
                          downloadable: true,
                          hide: isBroker,
                        },
                        {
                          name: "fileType",
                          title: "File Type",
                          tooltip: false,
                          downloadable: false,
                          hide: isBroker,
                        },
                        {
                          name: "fileDate",
                          title: "File Date",
                          tooltip: false,
                          downloadable: false,
                          hide: isBroker,
                        },
                      ]}
                      onExportTable={exportCsv}
                      downloadFile={requestDownload}
                      uid={item.uid}
                      contractName={item.contractName}
                      listItems={item.storms}
                    />
                  )
                : ""}

              <div>
                <div className="disclaimer-message">
                  <p>
                    <strong>Disclaimer:</strong> This dashboard is provided for convenience only. The dashboard does not
                    constitute an Event Notice and does not relieve Centauri Specialty Insurance Company of having to
                    make an Event Notice under the Reinsurance Agreement. Arbol does not take any responsibility for any
                    inaccuracies in this information. To the extent any information in this dashboard conflicts with
                    anything in the Reinsurance Agreement or any agreement between Arbol Inc. and Centauri Specialty
                    Insurance Company, such agreement shall govern.
                  </p>
                </div>

                {!item.active && !item.expired && !showSignToUser && (
                  <SignCallToAction
                    onSign={() => signContract(isDemo ? DEMO_UID : item.uid)}
                    loading={loadingSignature}
                  />
                )}
              </div>
            </div>
          </Collapsible>
        );
      })}
    </div>
  );

  return (
    <>
      <Header loggedAs={loggedAs} isDemo={isDemo} />

      {loading ? (
        <div
          style={{
            margin: "20% auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" indicator={antIcon} />
        </div>
      ) : (
        renderContent()
      )}
    </>
  );
}
