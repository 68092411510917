import { useState, useEffect } from "react";
import axios from "axios";
import { useSignIn } from "hooks";
import { USDollar, sortObject } from "helpers";
import { message } from "antd";

export const useContractConfig = isDemo => {
  const { getUserAddress } = useSignIn(isDemo);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);

  const requestDownload = async (base = "data", uid = "", filename = "") => {
    if (base && uid && filename) {
      try {
        // TODO - Move axios request outside JSX component
        const res = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/file/${base}/${uid}/${filename}`, {
          withCredentials: true,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const fileBlob = new Blob([res.data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${base}/${uid}/${filename}`);
        document.body.appendChild(link);

        link.click();
      } catch (err) {
        message.error("Failed to Download File: " + err, 5);
      }
    }
  };

  useEffect(() => {
    const loadFunc = async () => {
      try {
        setLoading(true);
        const address = await getUserAddress();
        const contractEndpoint =
          isDemo === true
            ? `${process.env.REACT_APP_SIWE_SERVER}/contract-data-demo/${address}`
            : `${process.env.REACT_APP_SIWE_SERVER}/contract-data/${address}`;

        const res = await axios.get(contractEndpoint, {
          withCredentials: true,
        });

        if (res.status === 200) {
          let sortedContracts = sortObject(res.data);

          sortedContracts = sortedContracts.map((contract, index) => ({
            ...contract,
            storms: contract.storms?.map(item => ({
              ...item,
              grossLoss: USDollar.format(item.grossLoss || 0),
              subjectLoss: USDollar.format(item.subjectLoss || 0),
            })),
          }));

          setContracts(sortedContracts);
        }
        setLoading(false);
      } catch (err) {
        message.error("Failed to get Contract Data: " + err, 5);
        setLoading(false);
      }
    };
    void loadFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { requestDownload, contracts, loading };
};
