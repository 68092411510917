import "./TosModal.less";
import { useState, useEffect } from "react";
import { Modal, Checkbox, Button } from "antd";

export default function TosModal() {
  const [accepted, setAccepted] = useState(false);
  const [open, setOpen] = useState(true);
  const [showModal, setModalVisibility] = useState(false);
  // TODO - TBD TOS handling
  // const tos = Boolean(localStorage.getItem("tos"));
  const tos = false;

  const onAcceptHandler = () => {
    console.log("Accepted the terms");
    // TODO - TBD TOS handling
    // localStorage.setItem("tos", true);
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setModalVisibility(true);
    }, 500);
  }, []);

  return (
    <>
      {!tos && showModal && (
        <Modal
          title="CENTAURI DASHBOARD AGREEMENT"
          visible={open}
          width={1000}
          closable={false}
          footer={[
            <Checkbox key="check" onChange={() => setAccepted(accepted => !accepted)}>
              I have read and agreed to the terms and conditions
            </Checkbox>,
            <Button key="submit" disabled={!accepted} type="primary" onClick={onAcceptHandler}>
              Accept Terms
            </Button>,
          ]}
        >
          <div className="modal-content">
            <p>
              This Agreement, effective as of July 11, 2023, governs the availability and use of the Centauri Dashboard,
              which Arbol Inc., a corporation domiciled in the State of Delaware (“Arbol”), is providing to Centauri
              Specialty Insurance Company, an insurance company domiciled in the State of Florida (including any and/or
              all companies that are or may hereafter become affiliated therewith) (“Centauri”) (each a “Party” and
              collectively the “Parties”).
            </p>

            <ol>
              <li>
                The Centauri Dashboard (the “Dashboard”) is an informational platform provided by Arbol to Centauri. The
                Dashboard is designed to communicate certain events associated with certain reinsurance agreements
                (including reinsurance agreements which may be entered into in the future) between Centauri and Artex
                SAC Limited acting for and on behalf of segregated accounts with which Arbol has entered into
                cooperation and indemnification agreements (each a “Reinsurance Agreement”). The information displayed
                in the Dashboard, including information relating to storms, contractual limits, and calculated losses,
                are stored in a specialty database called a blockchain. The Dashboard is a convenient mechanism to
                communicate and summarize this information, and it enables the Parties to verify the underlying
                information in a trustless manner.
              </li>
              <li>
                The Dashboard is intended for convenience only. The Dashboard does not constitute an Event Notice under
                any Reinsurance Agreement and does not relieve Centauri of having to make an Event Notice under any
                Reinsurance Agreement.
              </li>
              <li>
                Arbol makes no warranty, express or implied, concerning the Dashboard or the information contained
                therein. All information contained in the Dashboard is presented “as is.” Arbol expressly disclaims any
                implied warranty of merchantability or fitness for a particular purpose, including any warranty for the
                use or the results of the use of the Dashboard with respect to its correctness, quality, accuracy,
                completeness, reliability, performance, or timeliness. The Dashboard may experience unavailability or
                downtime, and such unavailability or downtime may be either announced or unannounced and either
                intentional or unintentional. Centauri acknowledges and agrees that Arbol, its advisors, consultants,
                shareholders, and other affiliated persons are not responsible for, shall not be liable for, and make no
                representations, guarantees, or warranties regarding the accuracy, completeness, or fairness of the
                information contained in the Dashboard, or of the reliability, stability, durability, or security of the
                Dashboard itself. The Dashboard relies on certain third-party technology including, but not limited to,
                the Canopy Network (developed by The Institutes RiskStream Collaborative, Inc. and third-party
                contributors) and MetaMask (developed by ConsenSys Software Inc. and third-party contributors). Arbol is
                not affiliated and does not control those third parties, and makes no representations, guarantees, or
                warranties regarding the security or reliability of third-party technology.
              </li>
              <li>
                In no event will either Party or its respective affiliates have any liability arising out of or related
                to this Agreement for any lost profits, revenues, goodwill, or indirect, special, incidental,
                consequential, cover, business interruption, or punitive damages, whether an action is in contract or
                tort and regardless of the theory of liability, even if a Party or its affiliates have been advised of
                the possibility of such damages or if a Party’s or its affiliates’ remedy otherwise fails of its
                essential purpose.
              </li>
              <li>
                To the extent there is any conflict or explicit or implicit inconsistency between (a) information
                contained in or displayed by the Dashboard, and (b) the terms or conditions provided for in any
                Reinsurance Agreement or any other applicable contract or agreement entered into between the Parties,
                the latter shall govern. The Dashboard and information communicated thereby shall not constitute a
                “notice” for the purposes of any agreement between the Parties unless otherwise explicitly provided in
                such agreement. To the extent any Reinsurance Agreement contains a provision governing the delivery of
                notices between the parties, such provision shall govern relevant notices notwithstanding any
                information that may be contained in the Dashboard.
              </li>
              <li>
                Arbol acknowledges that the information contained in the Dashboard may be subject to confidentiality
                obligations covered by one or more Reinsurance Agreements or by non-disclosure agreements entered into
                between the Parties. The Dashboard is intended to display information only to specifically authorized
                users. The security mechanism utilized by the Dashboard relies on the execution of “smart contracts.” To
                utilize that mechanism, Centauri will be required to use cryptographic keys, including keys that must be
                maintained confidentially and securely. Arbol shall instruct Centauri and its designated representatives
                how to maintain and use such cryptographic keys. It will be Centauri’s sole responsibility to maintain
                its keys. Arbol shall not be responsible for any unauthorized access to the Dashboard or any disclosure
                of information contained in the Dashboard that is caused by or attributable to Centauri’s failure to
                properly maintain its cryptographic keys.
              </li>
              <li>
                Arbol hereby grants to Centauri a non-exclusive, revocable license limited for the purpose of using the
                Dashboard. Nothing in this Agreement or in Arbol’s provision of the Dashboard to Centauri shall
                constitute the grant of any other license to any intellectual property or any other use of intellectual
                property, including but not limited to intellectual property in the software code, multimedia, or text
                comprising the Dashboard. Intellectual property rights to certain portions of the Dashboard may be owned
                by third parties and licensed by Arbol. Centauri agrees not to reverse engineer, disassemble, or
                decompile any portion of the Dashboard.
              </li>
              <li>
                This Agreement is the sole agreement with respect to the subject matter hereof and supersedes all other
                agreements and understandings between the Parties with respect to the same, including but not limited to
                the Centauri Dashboard Agreement dated May 10, 2023. This Agreement shall be governed by the laws of the
                State of New York without regard to conflict-of-law principles. The Parties agree that the state and
                federal courts in New York County in the State of New York (as well as any appellate courts therefrom)
                shall have exclusive jurisdiction over and shall be the exclusive venue for disputes arising under this
                Agreement. If any dispute arising under this Agreement is brought in such court, neither Party shall
                make any objection to venue or personal jurisdiction. This Agreement is for the sole benefit of the
                Parties hereto and their respective successors and permitted assigns and nothing herein, express or
                implied, is intended to or shall confer upon any other person any legal or equitable right, benefit, or
                remedy of any nature whatsoever, under or by reason of this Agreement. If any provision of this
                Agreement shall be held to be invalid or unenforceable for any reason, the remaining provisions shall
                continue to be valid and enforceable. If a court or other tribunal finds that any provision of this
                Agreement is invalid or unenforceable, but that by limiting such provision it would become valid and
                enforceable, then such provision shall be deemed to be written, construed, and enforced as so limited.
              </li>
            </ol>
          </div>
        </Modal>
      )}
    </>
  );
}
