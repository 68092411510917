import { useState } from "react";
import "./StormDocumentTable.less";
import { Checkbox, Tooltip, Button } from "antd";
import { ReactComponent as TableDownloadIcon } from "assets/svg/table-download-icon.svg";
import { ReactComponent as InfoIcon } from "assets/svg/info-icon.svg";
import { ReactComponent as ExportIcon } from "assets/svg/export-icon.svg";

import Card from "./Card";

const tooltip_color = "rgba(45, 49, 62, 0.8)";

export default function StormTable({
  listItems = [],
  columns = [],
  uid = "",
  contractName = "",
  onExportTable = () => {},
  downloadFile = () => {},
}) {
  const [isCheckAll, setCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const onExport = () => {
    const rowsToExport = listItems?.filter(item => isCheck.includes(item.name));
    const mappedRows = rowsToExport.map(item => ({
      name: `"${item.name}"`,
      date: `"${item.date}"`,
      parametricModeledLoss: `"${item.grossLoss}"`,
      lossAmount: `"${item.subjectLoss}"`,
    }));
    onExportTable(contractName, mappedRows);
  };

  const onCheckAll = () => {
    setCheckAll(isCheckAll => !isCheckAll);
    setIsCheck(listItems?.map(item => item.name));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const onCheckOne = e => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, value]);

    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== value));
    }
  };

  return (
    <Card className="storm-document">
      <Card.Header>
        <h3>Storm Document</h3>
        <Button type="text" disabled={isCheck.length <= 0} onClick={() => onExport()} className="export-storm-document">
          <ExportIcon />
          Export Selected Rows
        </Button>
      </Card.Header>

      <Card.Content>
        <div>
          <div className="storm-table">
            <div className="select-document-colum">
              <Checkbox onChange={onCheckAll} checked={isCheckAll} />
            </div>

            {columns?.map(
              (column, index) =>
                !column.hide && (
                  <div
                    key={index}
                    style={{
                      textAlign: ["grossLoss", "subjectLoss"].includes(column.name) ? "right" : "left",
                    }}
                  >
                    {column.tooltip ? (
                      <Tooltip title={column.tooltip} placement="bottomRight" color={tooltip_color}>
                        {column.title} <InfoIcon />
                      </Tooltip>
                    ) : (
                      column.title
                    )}
                  </div>
                ),
            )}
          </div>

          {listItems?.map((item, index) => (
            <div className="storm-table row" key={index}>
              <div className="select-document-colum">
                <Checkbox onChange={onCheckOne} value={item.name} checked={isCheck.includes(item.name)} />
              </div>

              {columns?.map(
                (column, index) =>
                  !column.hide && (
                    <div
                      key={index}
                      style={{
                        textAlign: ["grossLoss", "subjectLoss"].includes(column.name) ? "right" : "left",
                      }}
                    >
                      {column.downloadable && item.lossDetailFile ? (
                        <a
                          onClick={e => {
                            e.preventDefault();
                            downloadFile("payouts", uid, item.lossDetailFile);
                          }}
                          href="/"
                        >
                          <TableDownloadIcon /> Download
                        </a>
                      ) : (
                        item[column.name]
                      )}
                    </div>
                  ),
              )}
            </div>
          ))}
        </div>
      </Card.Content>
    </Card>
  );
}
