import axios from "axios";
import { useState, createContext, useContext } from "react";
import { ethers } from "ethers";
import EthCrypto from "eth-crypto";
import { SiweMessage } from "siwe";
import { message } from "antd";

const SignInContext = createContext();

export const SignInProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [loggedAs, setLoggedAs] = useState(null);

  return (
    <SignInContext.Provider value={{ isLoggedIn, loggedAs, setLoggedIn, setLoggedAs }}>
      {children}
    </SignInContext.Provider>
  );
};

export const useSignIn = () => {
  const { isLoggedIn, setLoggedIn, loggedAs, setLoggedAs } = useContext(SignInContext);
  const [loading, setLoading] = useState(false);
  const [pKey, setPKey] = useState("");

  const domain = window.location.host;
  const origin = window.location.origin;
  const provider = window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider && provider.getSigner();

  const createSiweMessage = async (address, statement) => {
    const { data = null } = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/nonce`, {
      withCredentials: true,
    });

    const { data: chainId = null } = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/chainId`, {
      withCredentials: true,
    });

    const siweMessage = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId,
      nonce: data || "",
    });

    return siweMessage.prepareMessage();
  };

  const signIn = async () => {
    try {
      setLoading(true);
      const message = await createSiweMessage(await signer.getAddress(), "Sign in with Ethereum to the app.");
      const signature = await signer.signMessage(message);
      const res = await axios.post(
        `${process.env.REACT_APP_SIWE_SERVER}/verify`,
        { message, signature },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      setLoggedIn(res.status === 200);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response?.status !== 401) {
        message.error("Failed to Sign In: " + err.message?.toString(), 5);
      }
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/logout`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      setLoggedIn(null);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response?.status !== 401) {
        message.error("Failed to Sign Out: " + err.message?.toString(), 5);
      }
      setLoading(false);
    }
  };

  const generatePKey = async () => {
    try {
      setLoading(true);
      const message = await createSiweMessage(await signer.getAddress(), "Generate Your Public Key");
      const signature = await signer.signMessage(message);

      const msgHash = ethers.utils.hashMessage(message);
      const msgHashBytes = ethers.utils.arrayify(msgHash);
      const recoveredPublicKey = ethers.utils.recoverPublicKey(msgHashBytes, signature);
      const compressedPublicKey = EthCrypto.publicKey.compress(Buffer.from(recoveredPublicKey.slice(2), "hex"));

      setPKey(compressedPublicKey.toString("hex"));
      setLoading(false);
    } catch (err) {
      if (err.response && err.response?.status !== 401) {
        message.error("Failed to Sign In: " + err.message?.toString(), 5);
      }
      setLoading(false);
    }
  };

  const resetSignatures = async address => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/reset-signatures/${address}`, {
        withCredentials: true,
      });
      console.log(res);
    } catch (err) {
      if (err.response && err.response?.status !== 401) {
        console.error("Failed to get Reset Authorizations: " + err.response?.data?.message?.toString());
      }
    }
  };

  const getSessionInfo = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/personal-information`, {
        withCredentials: true,
      });

      setLoggedIn(res.status === 200);
      setLoggedAs(res.data.loggedAs);
    } catch (err) {
      if (err.response && err.response?.status !== 401) {
        message.error("Failed to get Session Info: " + err.response?.data?.message?.toString(), 5);
      }

      setLoggedIn(false);
    }
  };

  const getSessionInfoDemo = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/personal-information-demo`, {
        withCredentials: true,
      });

      console.log({ loggedAs: res.data.loggedAs });
      setLoggedIn(res.status === 200);
      setLoggedAs(res.data.loggedAs);
    } catch (err) {
      if (err.response && err.response?.status !== 401) {
        message.error("Failed to get Session Info: " + err.response?.data?.message?.toString(), 5);
      }

      setLoggedIn(false);
    }
  };

  const getUserAddress = async () => {
    try {
      return await signer.getAddress();
    } catch (err) {
      console.error("Failed to get User Address: " + err.message?.toString());
    }
  };

  return {
    loading,
    isLoggedIn,
    loggedAs,
    pKey,
    getSessionInfo,
    getSessionInfoDemo,
    signIn,
    signOut,
    generatePKey,
    getUserAddress,
    resetSignatures,
  };
};

export default useSignIn;
