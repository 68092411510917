import { useState } from "react";
import "./Collapsible.less";

import CollapsiblePanel from "./CollapsiblePanel";

export default function Collapsible({ open = false, active = false, expired = false, children, panelItems = [] }) {
  const [isOpen, toggleCollapsible] = useState(open);

  return (
    <div className={`collapsible-wrapper ${isOpen ? "open" : ""}`}>
      <CollapsiblePanel
        isOpen={isOpen}
        active={active}
        expired={expired}
        header={panelItems}
        onClick={() => toggleCollapsible(isOpen => !isOpen)}
      >
        {children}
      </CollapsiblePanel>
    </div>
  );
}
