import { useState } from "react";
import { Header } from "components";
import { useAccount } from "wagmi";
import { Button, Result } from "antd";
import { useSignIn } from "hooks";

export default function ResetAuthorizations() {
  const { address } = useAccount();
  const { resetSignatures } = useSignIn();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setSuccess(false);
    setLoading(true);
    await resetSignatures(address);
    setSuccess(true);
    setLoading(false);
  };

  return (
    <>
      <Header />

      <div
        className="content"
        style={{
          marginTop: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>Reset Authorizations for address: {address}</h3>
        <br />
        <Button key="connect" type="primary" loading={loading} style={{ width: 300, height: 50 }} onClick={handleClick}>
          Reset
        </Button>
        {success && (
          <Result
            status="success"
            icon={false}
            title={
              <div>
                <p>Signatures Reset!</p>
              </div>
            }
          />
        )}
      </div>
    </>
  );
}
