import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAccount } from "wagmi";
import { useConnectModal, ConnectButton } from "@rainbow-me/rainbowkit";
import { useSignIn } from "hooks";
import { Header, TosModal } from "components";
import { Button } from "antd";

export default function Connect({ isDemo = false }) {
  const [triggerSignIn, setSignInTrigger] = useState(false);
  const { loading, isLoggedIn, signIn, getSessionInfo, getSessionInfoDemo } = useSignIn();
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();

  const handleClick = async () => {
    if (!isConnected && openConnectModal) {
      openConnectModal();
      setSignInTrigger(true);
    }
  };

  const signAndRetrieveUser = async () => {
    await signIn();
    isDemo === true ? await getSessionInfoDemo() : await getSessionInfo();
  };

  useEffect(() => {
    if (isConnected && triggerSignIn) {
      signAndRetrieveUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    isDemo === true ? getSessionInfoDemo() : getSessionInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToDashboard = () => <Redirect push to={isDemo === true ? "/demo-dashboard" : "/dashboard"} />;

  const renderConnectPage = () => (
    <>
      <TosModal />

      <Header />

      <div
        className="content"
        style={{
          marginTop: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ConnectButton.Custom>
          {() => {
            return (
              <Button
                key="connect"
                type="primary"
                loading={loading}
                style={{ width: 300, height: 50 }}
                onClick={isConnected ? signAndRetrieveUser : handleClick}
              >
                {isConnected ? "Sign in with Ethereum" : "Connect Wallet with Metamask"}
              </Button>
            );
          }}
        </ConnectButton.Custom>
      </div>
    </>
  );

  if (isLoggedIn === true) return redirectToDashboard();
  if (isLoggedIn === null) {
    return (
      <>
        <Header />

        <div
          className="content"
          style={{
            marginTop: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>Redirecting..</p>
        </div>
      </>
    );
  }

  return renderConnectPage();
}
